import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckGood } from '../../../app/api/interfaces/check-good.interface';
import { IGood } from '../../../app/api/interfaces/good.interface';
import { Loader } from '../../ui';
import classes from './rozetkaGood.module.css'

interface GoodProps {
    children?: React.ReactNode;
    goodData: ICheckGood | undefined;
    goodIsLoading: boolean;
    sarchFound: boolean;
}

export const RozetkaGood: FC<GoodProps> = ({ goodData, goodIsLoading, sarchFound }) => {
    const { t } = useTranslation(['check', 'ui']);
    const [showName, setShowName] = useState<string>('');

    useEffect(() => {
        if (!sarchFound && goodData && goodData.name) {
            try {
                setShowName(JSON.parse(goodData.name))
            } catch (e) {
                setShowName(goodData.name)
            }
        } else {
            setShowName('')
        }
    }, [goodData])
    return (
        <div className={[classes.goodWrapper, !sarchFound ? classes.removeMargin : undefined].join(" ")}>
            {goodIsLoading &&
                <div className={classes.loader}>
                    <Loader />
                </div>
            }
            {goodData &&
                <>
                    {/* <div className={classes.imageWrapper}>
                        <img className={classes.img} src={goodData.bigImg ?? goodData.img} alt="good image" />
                    </div> */}
                    <div className={classes.descriptionWrapper} id='rozetkaInfo'>
                        <div className={classes.name}>{t('onRozetka')}</div>
                        {showName &&
                            <div className={classes.price}>{t('name')}: <span className={classes.blackColor}>{showName}</span></div>
                        }
                        <div className={classes.price}>{t('price')}: <span className={classes.blackColor}>{goodData.price.price} грн.</span></div>
                        <div className={classes.discount}>
                            {t('discount')}:

                            {goodData.price.discount > 0 ?
                                <span className={classes.greenColor}>{` ${goodData.price.discount}%`}</span>
                                : goodData.price.discount === 0 ? <span>{' 0%'}</span> : <span>{` +${Math.abs(goodData.price.discount)}%`}</span>}


                        </div>
                        {goodData.lowPrice &&
                            <div className={classes.price}>{t('lowPrice')}: <span>{goodData.lowPrice} грн.</span></div>
                        }
                        <a href={goodData.link} target='_blank'>
                            <button className={classes.goodButton}>{t('actionBtn')}</button>
                        </a>
                    </div>
                </>
            }
        </div>
    )
}
