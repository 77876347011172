import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckGood } from '../../../app/api/interfaces/check-good.interface';
import { IGood } from '../../../app/api/interfaces/good.interface';
import { FillBtn, Loader, OutletBtn } from '../../ui';
import classes from './good.module.css'
import { IProductAllWithName } from '../../../app/api/interfaces/product-all.interface';
import goodSvg from './img/any/good.svg'
import topSvg from './img/any/top.svg'
import shopSvg from './img/any/shop.svg'
import priceSvg from './img/any/price.svg'

interface GoodProps {
    children?: React.ReactNode;
    goodData: IProductAllWithName | undefined;
    goodIsLoading: boolean;
    goodIsFetching: boolean;
    localRozetkaGoodData: ICheckGood | { status: false; } | undefined;
}

interface SortConfig {
    key: keyof IGood | null;
    direction: 'ascending' | 'descending';
}

export const Good: FC<GoodProps> = ({ goodData, goodIsLoading, localRozetkaGoodData, goodIsFetching }) => {
    const { t } = useTranslation(['check', 'ui']);
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });

    const onSort = (key: any) => {
        let direction: "ascending" | "descending" = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedGoods = useMemo(() => {
        if (!goodData) return [];

        let sortableGoods = [...goodData.products];
        if (sortConfig.key !== null) {
            sortableGoods.sort((a, b) => {
                // @ts-ignore
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                // @ts-ignore
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableGoods;
    }, [goodData, sortConfig]);

    // get minimum price number
    const minimumPrice = useMemo(() => {
        if (!goodData) return 0;
        return goodData.products.reduce((min, item) => item.price < min ? item.price : min, goodData.products[0].price);
    }, [goodData]);


    // smooth to #rozetkaInfo scroll

    const smoothScroll = () => {
        const element = document.getElementById('rozetkaInfo');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }

    return (
        <div className={classes.goodWrapper}>
            {(goodIsLoading || goodIsFetching) &&
                <div className={classes.loader}>
                    <Loader />
                </div>
            }
            {(goodData && goodData.products.length > 0 && !goodIsLoading && !goodIsFetching) &&
                <>
                    <div className={classes.descriptionWrapper}>
                        <div className={classes.name}>{t('name')}:  <span className={classes.productNameTitle}>{goodData.productName}</span></div>
                        <div className={classes.price}>{t('prices')}: <span className={classes.greenColor}>{minimumPrice} - {goodData.products[goodData.products.length - 1].price} грн</span></div>
                        {localRozetkaGoodData &&
                            <div className={classes.showGraph}>
                                <OutletBtn onClick={smoothScroll}>{t('showRozetkaInfo')}</OutletBtn>
                            </div>
                        }
                    </div>
                    <table className={classes.goodTable}>
                        <thead>
                            <tr>
                                <th onClick={() => onSort('name')} className={classes.tableHead}>
                                    <img src={shopSvg} className={classes.tableHeadIcon}></img>
                                    <span>{t('shops')}</span>
                                </th>
                                <th onClick={() => onSort('price')} className={classes.tableHead}>
                                    <img src={priceSvg} className={classes.tableHeadIcon}></img>
                                    <span>{t('prices')}</span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedGoods.map((good, index) => (
                                <tr key={index}>
                                    <td className={classes.tableProductName}>{good.shopName}</td>
                                    <td className={classes.tablePrice}>
                                        <span>{good.price} грн</span>
                                        {good.isBestPrice &&
                                            <img src={goodSvg} className={classes.tablePriceIcon}></img>
                                        }
                                        {good.isPromo &&
                                            <img src={topSvg} className={classes.tablePriceIcon}></img>
                                        }
                                    </td>
                                    <td className={classes.tdGoodButton}>
                                        <a href={good.link} target='_blank'>
                                            <button className={classes.goodButton}>{t('actionBtn')}</button>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            }
            {(goodData && goodData.products.length === 0 && !localRozetkaGoodData) && (
                <div className={classes.notFound}>{t('notFoundOther')}</div>
            )}
        </div>
    )
}
