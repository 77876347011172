import { t } from 'i18next';
import { useEffect, useState, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { truePriceAPI } from '../../app/api/true-price-api';
import { Check, Description, Search, Good } from '../../features/check';
import { Loader } from '../../features/ui';
import classes from './check.module.css';
import { Helmet } from 'react-helmet-async';
import { RozetkaGood } from '../../features/check/rozetkaGood/rozetkaGood.component';
import { IProductAllWithName } from '../../app/api/interfaces/product-all.interface';
import { ICheckGood } from '../../app/api/interfaces/check-good.interface';
import SDKProvider from '../../features/check/search/sdk';

const Chart = lazy(() => import('../../features/check/chart/chart.component'));

export const CheckPage = () => {
    const { t } = useTranslation(['check', 'ui']);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState('');
    const [rozetkaSearchValue, setRozetkaSearchValue] = useState({ search: '', isBarcode: false });
    const [showError, setShowError] = useState(false);
    const [runCheck, setRunCheck] = useState(false);
    const [runSiteSearch, setRunSiteSearch] = useState(false);
    const [localRozetkaGoodData, setLocalRozetkaGoodData] = useState<ICheckGood | { status: false; } | undefined>(undefined);
    const [localGoodData, setLocalGoodData] = useState<IProductAllWithName | undefined>(undefined);
    const [chartId, setChartId] = useState('');
    const [modal, setModal] = useState(false);
    const [showNotFound, setShowNotFound] = useState(false);

    const {
        data: rozetkaGoodData,
        isLoading: rozetkaGoodIsLoading,
        error: rozetkaGoodError,
        isFetching: rozetkaGoodIsFetching,
    } = truePriceAPI.useFetchGoodDataQuery(
        { id: rozetkaSearchValue.search, isBarcode: rozetkaSearchValue.isBarcode },
        { skip: rozetkaSearchValue.search === '' || !runCheck }
    );

    const {
        data: chartData,
        isLoading: chartIsLoading,
        error: chartError,
        isFetching: chartIsFetching,
    } = truePriceAPI.useFetchChartDataQuery(
        { id: chartId },
        { skip: !chartId }
    );

    const {
        data: goodData,
        isLoading: goodIsLoading,
        error: goodError,
        isFetching: goodIsFetching,
    } = truePriceAPI.useFetchSearchGoodDataQuery(
        { productName: searchValue },
        { skip: searchValue === '' || !runSiteSearch }
    );

    const updateSearchValue = (value: string) => {
        setSearchValue('');
        setLocalGoodData(undefined);
        setLocalRozetkaGoodData(undefined);
        setChartId('');
        setRozetkaSearchValue({ search: '', isBarcode: false });
        setRunCheck(false);
        setModal(false);
        setRunSiteSearch(false);
        setShowNotFound(false);

        if (!value) {
            return;
        }
        setShowError(false);
        setSearchValue(value);
        const linkRegex = /\/[A-z]([0-9]+)[\/]?$/;
        const linkMatch = value.match(linkRegex);
        if (linkMatch && linkMatch[1]) {
            setRunCheck(true);
            setRunSiteSearch(false);
            setRozetkaSearchValue({ search: linkMatch[1], isBarcode: false });
            return;
        }

        const isNumeric = /^\d+$/.test(value);
        if (isNumeric) {
            setRunCheck(true);
            setRunSiteSearch(false);
            setRozetkaSearchValue({ search: value, isBarcode: true });
            return;
        }

        setRunSiteSearch(true);

        setLocalRozetkaGoodData(undefined);
    };

    useEffect(() => {
        if (searchParams.has('search')) {
            const searchQuery = searchParams.get('search');
            setModal(true);
            updateSearchValue(searchQuery || '');
            searchParams.delete('search');
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    useEffect(() => {
        if (rozetkaGoodData && rozetkaGoodData !== undefined && !('status' in rozetkaGoodData) && rozetkaGoodData['rozetkaId']) {
            if (!searchValue) {
                setSearchValue(rozetkaGoodData.name);
            }
            setChartId(rozetkaGoodData.rozetkaId);
            if (!runCheck) {
                setRunCheck(true);
            }
            if (!runSiteSearch) {
                setSearchValue(rozetkaGoodData.name);
                setRunSiteSearch(true);
            }
        } else {
            if (!runSiteSearch) {
                setSearchValue(rozetkaSearchValue.search);
                setRunSiteSearch(true);
            }

        }
        if (!localRozetkaGoodData) {
            setLocalRozetkaGoodData(rozetkaGoodData);
        }
    }, [rozetkaGoodData]);


    useEffect(() => {
        if (((localRozetkaGoodData && 'status' in localRozetkaGoodData && localRozetkaGoodData['status'] === false)) && !goodIsFetching && !rozetkaGoodIsFetching) {
            setShowNotFound(true);
        } else {
            setShowNotFound(false);
        }
    }, [localGoodData, localRozetkaGoodData, goodIsFetching, rozetkaGoodIsFetching]);

    useEffect(() => {
        if (goodData && goodData !== undefined && goodData['productName'] && goodData['productName'] !== '') {
            setRozetkaSearchValue({ search: goodData['productName'], isBarcode: true });
            if (!runCheck) {
                setRunCheck(true);
            }
            if (!runSiteSearch) {
                setRunSiteSearch(true);
            }
        }
        setLocalGoodData(goodData);
    }, [goodData]);

    return (
        <>
            <Helmet>
                <title>True Price - Перевірка ціни</title>
                <link rel="canonical" href="https://true-price.top/check" />
            </Helmet>
            <Description />
            <SDKProvider>
                <Search updateSearchValue={updateSearchValue} showError={showError} />
            </SDKProvider>
            {/* {(!goodData && !rozetkaGoodData && !localRozetkaGoodData && !chartData && !goodIsFetching) &&
                <div className={classes.warning}>{t('warning')}</div>
            } */}
            <div className={classes.body}>
                {((goodData && goodData['productName'] && goodData['productName'] !== '') || goodIsLoading || goodIsFetching) && (
                    <Good goodData={localGoodData} goodIsLoading={goodIsLoading} goodIsFetching={goodIsFetching} localRozetkaGoodData={localRozetkaGoodData} />
                )}
                {((rozetkaGoodData && 'status' in rozetkaGoodData && rozetkaGoodData['status'] === false && goodData && goodData['products'] && goodData['products'].length === 0) || showNotFound) && (
                    <div className={classes.notFound}> {t('notFound', { goodId: searchValue })} </div>
                )}
                {(localRozetkaGoodData && localRozetkaGoodData !== undefined && chartData && !('status' in localRozetkaGoodData)) && (
                    <>
                        <RozetkaGood goodData={localRozetkaGoodData} sarchFound={!localGoodData && goodData && goodData['productName'] && goodData['productName'] !== '' ? true : false} goodIsLoading={rozetkaGoodIsLoading} />
                        <div className={classes.chartWrapper}>
                            <Suspense fallback={<Loader />}>
                                <Chart chartData={chartData} chartIsLoading={chartIsLoading} />
                            </Suspense>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
