import { AdminChartDto } from './dto/admin/admin-chart.dto';
import { IChart } from './interfaces/admin/chart.interface';
import { OnlineUsersHistoryDto } from './dto/admin/online-users-histrory.dto';
import { IOnlineUsersHistory } from './interfaces/admin/online-users-history.interface';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DiscountDto } from './dto/discount.dto';
import { GoogleAuthDto } from './dto/google-auth.dto';
import { LoginDto } from './dto/login.dto';
import { MarkViewedDto } from './dto/mark-viewed.dto';
import { NewUsersDto } from './dto/admin/new-users.dto';
import { RegistrationDto } from './dto/registration.dto';
import { UpdateSubscribeNotificationDto } from './dto/update-subscribe-notification.dto';
import { IOwnCategoryList } from './interfaces/category-list.interface';
import { IDiscountPrice } from './interfaces/discount-price.interface';
import { IDiscount } from './interfaces/discount.interface';
import { ILinkAndStatus } from './interfaces/link-status.interface';
import { IMessage } from './interfaces/messagess.interface';
import { INewUsers } from './interfaces/admin/new-users.interface';
import { IOnline } from './interfaces/online.interface';
import { IRedirect } from './interfaces/redirect.interface';
import { ISubscribeNotificationsFullList } from './interfaces/subscribe-notifications-full-list.interface';
import { IUser } from './interfaces/user.interface';
import { IMultiChart } from './interfaces/admin/multi-chart.interface';
import { IParsed } from './interfaces/parsed.interface';
import { ICreatedInvoice } from './interfaces/create-invoice.interface';
import { ICheckSub } from './interfaces/check-sub.interface';
import { IMain } from './interfaces/main.interface';
import { UninstallDto } from './dto/uninstall.dto';
import { IGood } from './interfaces/good.interface';
import { ICheckGood } from './interfaces/check-good.interface';
import { IProductAllWithName } from './interfaces/product-all.interface';
import {IStatistics} from './interfaces/admin/statistics.interface';

export const truePriceAPI = createApi({
    reducerPath: 'truePriceAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://true-price.top/api',
    }),
    tagTypes: ['ProductSubscribe', 'TelegramSubscribe'],
    endpoints: (build) => ({
        fetchCategoryList: build.query<IOwnCategoryList[], any>({
            query: () => ({
                url: `/category/list`,
            }),
        }),
        fetchGetGoods: build.query<IDiscount[], { token: string; body: DiscountDto }>({
            query: ({ token, body }) => ({
                url: `/discounts/list`,
                method: 'POST',
                // headers: {
                //     Authorization: `Bearer ${token}`,
                // },
                body,
            }),
        }),
        // fetchRegistration: build.query<IUser, RegistrationDto>({
        //     query: (body) => ({
        //         url: `/auth/registration`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        fetchRegistrationGoogle: build.query<IUser, GoogleAuthDto>({
            query: (body) => ({
                url: `/auth/registration/google`,
                method: 'POST',
                body,
            }),
        }),
        // fetchLogin: build.query<IUser, GoogleAuthDto>({
        //     query: (body) => ({
        //         url: `/auth/login/google`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        fetchLoginGoogle: build.query<IUser, GoogleAuthDto>({
            query: (body) => ({
                url: `/auth/login/google`,
                method: 'POST',
                body,
            }),
        }),
        fetchRefreshToken: build.query<IUser, any>({
            query: () => ({
                url: `/auth/refresh`,
                method: 'POST',
                credentials: 'include',
                headers: {
                    mode: 'cors',
                },
            }),
        }),
        fetchGetUserInfo: build.query<any, any>({
            query: (body) => ({
                url: `/users/get-user`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${body}`,
                },
            }),
        }),
        fetchSubscribeNotificationsFullList: build.query<ISubscribeNotificationsFullList[], string>({
            query: (body) => ({
                url: `/subscribe-product/full-list`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${body}`,
                },
            }),
            providesTags: ['ProductSubscribe'],
        }),
        fetchDiscountPrice: build.query<IDiscountPrice[], { token: string; ids: string[] }>({
            query: ({ token, ids }) => ({
                url: `/goods/discount-price`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    ids,
                },
            }),
            providesTags: ['ProductSubscribe'],
        }),
        fetchNotificationsFullList: build.query<IMessage[], { token: string }>({
            query: ({ token }) => ({
                url: `/notifications/full-list`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        fetchMarkAsViewed: build.query<any, { token: string; markViewedDto: MarkViewedDto }>({
            query: ({ token, markViewedDto }) => ({
                url: `/notifications/mark-viewed`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: markViewedDto,
            }),
        }),
        fetchUpdateSubscribeSettings: build.query<any, { token: string; subscribeNotificationsFullList: UpdateSubscribeNotificationDto }>({
            query: ({ token, subscribeNotificationsFullList }) => ({
                url: `/subscribe-product/create`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: subscribeNotificationsFullList,
            }),
        }),
        fetchUnsubscribeProduct: build.mutation<any, { token: string; goodId: number }>({
            query: ({ token, goodId }) => ({
                url: `/subscribe-product/unsubscribe/${goodId}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: ['ProductSubscribe'],
        }),
        fetchUnsubscribeProducts: build.mutation<any, { token: string; ids: number[] }>({
            query: ({ token, ids }) => ({
                url: `/subscribe-product/unsubscribes`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    ids,
                },
            }),
            invalidatesTags: ['ProductSubscribe'],
        }),
        fetchAddSubPush: build.query<any, { token: string; subData: string }>({
            query: ({ token, subData }) => ({
                url: `/push/add`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    subData,
                },
            }),
        }),
        fetchCheckSubPush: build.query<any, { token: string; subData: string }>({
            query: ({ token, subData }) => ({
                url: `/push/check`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    subData,
                },
            }),
        }),
        fetchRemoveSubPush: build.query<any, { token: string; subData: string }>({
            query: ({ token, subData }) => ({
                url: `/push/user-remove`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    subData,
                },
            }),
        }),
        fetchLinkStatusTelegram: build.query<ILinkAndStatus, { token: string }>({
            query: ({ token }) => ({
                url: `/telegram/link-status`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            providesTags: ['TelegramSubscribe'],
        }),
        // fetchStopTelegram: build.query<any, { token: string }>({
        //     query: ({ token }) => ({
        //         url: `/telegram/stop`,
        //         method: 'GET',
        //         headers: {
        //             Authorization: `Bearer ${token}`,
        //         },
        //     }),
        // }),
        fetchToggleTelegram: build.mutation<any, { token: string }>({
            query: ({ token }) => ({
                url: `/telegram/toggle`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: ['TelegramSubscribe'],
        }),
        fetchRedirect: build.query<IRedirect, { id: string; origin?: string }>({
            query: ({ id, origin = 'cabinet' }) => ({
                url: `/notifications/redirect/${id}/${origin}`,
                method: 'GET',
            }),
        }),
        fetchRedirectAll: build.query<IRedirect, { id: string; origin?: string }>({
            query: ({ id, origin = 'cabinet' }) => ({
                url: `all/redirect/${origin}`,
                method: 'GET',
            }),
        }),
        fetchOnline: build.query<IOnline, { token: string }>({
            query: ({ token }) => ({
                url: `/statistics/count/online`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        fetchNewUsers: build.query<INewUsers, NewUsersDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/new-users`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchOnlineUsers: build.query<IOnlineUsersHistory, OnlineUsersHistoryDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/online-users`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchTableStatistics: build.query<IStatistics, OnlineUsersHistoryDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/table`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchRemoved: build.query<IChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/removed`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchChurnRate: build.query<IChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/churn-rate`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountMenu: build.query<IChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/menu`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountChart: build.query<IChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/chart`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountSort: build.query<IChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/sort`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountDiscount: build.query<IChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/discount`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountSubscriptions: build.query<IChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/goods-subscriptions`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountMultiSubscriptionsByTraffic: build.query<IMultiChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/multi/goods-subscriptions-traffic`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountMultiSubscriptionsByType: build.query<IMultiChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/multi/goods-subscriptions-type`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountNotifications: build.query<IChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/notifications`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountMultiNotifications: build.query<IMultiChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/multi/notifications`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchCountMultiNotificationsByTraffic: build.query<IMultiChart, AdminChartDto>({
            query: ({ token, start, end }) => ({
                url: `/statistics/count/multi/notifications-traffic`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    start,
                    end,
                },
            }),
        }),
        fetchParsed: build.query<IParsed, { token: string }>({
            query: ({ token }) => ({
                url: `/goods/parsed`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        fetchCreateInvoice: build.query<ICreatedInvoice, { token: string }>({
            query: ({ token }) => ({
                url: `/users/subscription/invoice`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        fetchCheckSub: build.query<ICheckSub, { token: string }>({
            query: ({ token }) => ({
                url: `/users/subscription/check`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        fetchCheckSubPayment: build.query<ICheckSub, { token: string }>({
            query: ({ token }) => ({
                url: `/users/subscription/payment-check`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        fetchCancelSub: build.query<void, { token: string }>({
            query: ({ token }) => ({
                url: `/users/subscription/cancel`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        fetchGetMainStatistics: build.query<IMain, void>({
            query: () => ({
                url: `/statistics/main`,
                method: 'GET',
            }),
        }),
        fetchFeedbackUninstall: build.query<void, UninstallDto>({
            query: (dto: UninstallDto) => ({
                url: `/feedback/uninstall`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: dto,
            }),
        }),
        fetchExtUninstall: build.query<void, { token: string }>({
            query: ({ token }) => ({
                url: `/feedback/ext-uninstall`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: token }),
            }),
        }),
        fetchGoodData: build.query<ICheckGood | { status: false }, { id: string, isBarcode: boolean }>({
            query: ({ id, isBarcode }) => isBarcode ? ({
                url: `/goods/check/`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productId: id, isBarcode: isBarcode }),
            }) : ({
                url: `/goods/check/${id}`,
                method: 'GET',
            }),
        }),
        fetchSearchGoodData: build.query<IProductAllWithName, { productName: string }>({
            query: ({ productName }) => ({
                url: `/all/site-search`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productName: productName }),
            }),
        }),
        fetchChartData: build.query<any, { id: string }>({
            query: ({ id }) => ({
                url: `/goods/price/${id}`,
                method: 'GET',
            }),
        }),
    }),
});
