import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import counterReducer from '../features/counter/counterSlice';
import languageReducer from '../features/header/language/languageSlice';
import authReducer from '../features/auth/auth.slice';
import { truePriceAPI } from './api/true-price-api';
import { authCheck } from './middlewares/auth-check';
export const store = configureStore({
    reducer: {
        counter: counterReducer,
        language: languageReducer,
        auth: authReducer,
        [truePriceAPI.reducerPath]: truePriceAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(truePriceAPI.middleware, authCheck),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
